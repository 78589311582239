import jm from './img/jm.jpg'
import downArrow from './img/down-chevron.svg'
import Navigation from './components/Navigation'

function App() {
  return (
    <>
      <Navigation></Navigation>
      <div className="c-content-body">
        <section className="c-intro">
          <img src={jm} className="c-profile" alt="James Macpherson" />
          <h1><span class="c-heading-bold">James</span> <span class="c-heading-light">Macpherson</span></h1>
          <p>Web Developer</p>

          <img src={downArrow} className="c-chevron" />
        </section>
        <section className="c-bio" id="about">
          <p>Hi, I'm James. I'm a Web Developer.</p>
          <p>I'm passionate about taking projects from conception to completion and working with companies to digitise their vision.</p>
          <p>I am flexible and adaptable and have worked solo, in small startup
          environments, SME's, large corporations and a range of industries from FinTech to Government.</p>
        </section>
        <section className="c-skills" id="skills">

          <div class="c-skills-left">
            <div class="c-skillset">
              <p>HTML</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '92%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>CSS</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '97%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>JS</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '90%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>Wordpress</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '86%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>Drupal</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '89%' }}></div>
              </div>
            </div>
          </div>
          <div class="c-skills-right">

            <div class="c-skillset">
              <p>React</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '89%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>Angular</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '89%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>Creative Cloud</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '92%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>Accessibility (WCAG)</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '89%' }}></div>
              </div>
            </div>
            <div class="c-skillset">
              <p>Cross Browser/Device Testing</p>
              <div class="c-skillset-bar">
                <div class="c-skillset-progress" style={{ width: '92%' }}></div>
              </div>
            </div>
          </div>

        </section>
        <section className="c-contact" id="contact">
          <a href="mailto:hi@jamesmac.com.au">hi@jamesmac.com.au</a><br />
          <a href="tel:0412759391">0412 759 391</a>
        </section>
      </div>
    </>
  );
}

export default App;
