import React from 'react'
import logo from '../img/logo.svg'

const Navigation = () => {
    return (
        <div className='c-navigation'>
            <a href="/"><img src={logo} /></a>

            <ul>
                <li><a href="#about">About</a></li>
                <li><a href="#skills">Skills</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>

            <p class="c-contact-me">
                <a href="mailto:hi@jamesmac.com.au">hi@jamesmac.com.au</a><br /><br />
                <a href="tel:0412759391">0412 759 391</a>

            </p>

        </div>
    )
}

export default Navigation
